import { Paths } from "../helpers/Constants";

export default class PasswordService {
  constructor() {
    this.callbacks = [];
    this.nextSubscriptionId = 6000;
    this.registerState = {};
    this.errorState = {};
  }

  validatePassword = (password) => {
    const input = password;
    if (!input) {
      return false;
    }

    const hasNumber = /[0-9]+/;
    const hasUpperChar = /[A-Z]+/;
    const hasLowerChar = /[a-z]+/;
    const hasSymbols = /[!@#$%^&*()_+=[{\]};:<>|./?,-]/;
    let rulesMeet = 0;

    if (input.length < 8) {
      return false;
    }

    if (hasLowerChar.test(input)) {
      rulesMeet += 1;
    }

    if (hasUpperChar.test(input)) {
      rulesMeet += 1;
    }

    if (hasNumber.test(input)) {
      rulesMeet += 1;
    }

    if (hasSymbols.test(input)) {
      rulesMeet += 1;
    }

    if (rulesMeet < 3) {
      return false;
    }

    return true;
  };

  passwordRules = () => {
    fetch(`${Paths.ApiPasswordUrl}/PasswordRules`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(() => {})
      .then((payload) =>
        this.handlePasswordRulesResponse({
          payload,
        }),
      )
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(error));
  };

  validatePasswordHistory = async (request) => {
    return (
      fetch(`${Paths.ApiPasswordUrl}/ValidatePassword`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
        credentials: "include",
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          return false;
        })
        // eslint-disable-next-line no-console
        .catch((error) => {
          console.warn(error);
          return false;
        })
    );
  };

  handlePasswordRulesResponse = (passwordRulesResponse) => {
    this.updatePasswordState(passwordRulesResponse);
  };

  forgotPassword = (email, applicationReturnUrl) => {
    if (email && applicationReturnUrl) {
      fetch(`${Paths.ApiPasswordUrl}/ResetPassword`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: email,
          applicationReturnUrl,
          // fcs redirects here with this flag if must update password
          // is set, this can eventually be pulled when the old mobile
          // app is sunset
          isFromFCS: window.location.href.toLowerCase().includes("isfromfcs")
        }),
        credentials: "include",
      })
        .then((resp) => resp.json())
        .then((payload) =>
          this.handleForgotPasswordResponse({
            ...payload,
            email,
            applicationReturnUrl,
          }),
        )
        // eslint-disable-next-line no-console
        .catch((error) => console.warn(error));
    }
  };

  handleForgotPasswordResponse = (forgotPasswordResponse) => {
    this.updatePasswordState({
      ...forgotPasswordResponse,
      loading: false,
      passwordReset: true,
    });
  };

  updatePasswordState = (newState) => {
    this.passwordState = newState;
    this.notifySubscribers();
  };

  getState() {
    return {
      passwordState: { ...this.passwordState },
    };
  }

  subscribe = (callback) => {
    this.callbacks.push({
      callback,
      // eslint-disable-next-line no-plusplus
      subscription: this.nextSubscriptionId++,
    });
    return this.nextSubscriptionId - 1;
  };

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this.callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      );
    }

    this.callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this.callbacks.length; i += 1) {
      const { callback } = this.callbacks[i];
      callback();
    }
  }
}
