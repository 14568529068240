import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiTextBox,
  FuiFlexContainer,
  FuiIcon,
  FuiCheckBox,
} from "@forcuraco/forcura-ui-components";
import { validator } from '@forcuraco/forcura-ui-components';

import Content from "../../shared/Content";
import Steps from "../../shared/Steps";
import Loading from "../../shared/Loading";
import UserAgreementAcceptanceList from "../../shared/UserAgreementAcceptanceList";
import { npiService } from "../../../services/NpiService";
import { accountUserAgreementService } from "../../../services/AccountUserAgreementService";
import TermsAndConditions from "./TermsAndConditions";

const NetworkConnectionError =  `Network error. Please check your network connection.`;

const NppesRegistrationError = 'An error occurred during registration. Please try again. If the error persists, please contact Forcura Support at 1-800-378-0596 for assistance.';

const ValidationConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'First Name is required'
    }
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'Last Name is required'
    }
  },
  npi: {
    presence: {
      allowEmpty: false,
      message: "NPI is required"
    },
    format: {
      pattern: /\d{10}/,
      message: "NPI must be a 10 digit number"
    }
  }
}

export default class NameAndNumber extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");

    this.state = {
      firstName: "",
      lastName: "",
      npi: "",
      firstNameError: [],
      lastNameError: [],
      npiError: null,
      npiAndNameError: null,
      isValidNameAndNpi: false,
      isValidNpi: false,
      loading: false,
      token,
      isTermsAndConditionsChecked: false,
      showTermsAndConditions: false,
      isUserAgreementChecked: false,
      providerInformation: {
        invitedByAccountId: null,
        accountName: null,
      },
    };

    this.baseState = this.state;
  }


  firstNameOnBlur = (event) => {
    const value = event.target.value || null;
    const errors = validator.validateSingle(value, ValidationConstraints['firstName']);
    this.setState({
      firstNameError: errors
    });
  };

  lastNameOnBlur = (event) => {
    const value = event.target.value || null;
    const errors = validator.validateSingle(value, ValidationConstraints['lastName']);
    this.setState({
      lastNameError: errors
    });
  };

  npiOnBlur = async (event) => {
    const value = event.target.value || null;

    const errors = validator.validateSingle(value, ValidationConstraints['npi']);

    this.setState({
      npiError: errors
    });

    if (!errors) {
      const { token } = this.state;

      try {
        const response = await npiService.isValidNpi(value, token);

        if (response.ok) {
          this.setState({ npiError: null });
        }
        else if (response.status === 404) {
          this.setState({ npiError: ["This is not a valid NPI"] })
        }
        else {
          this.setState({ npiError: [NppesRegistrationError] })
        }
      } catch(error) {
        console.warn(error);

        this.setState({ npiError: [NetworkConnectionError] })
      }
    }
  };

  onMouseDown = async (e) => {
    e.preventDefault();
    
    const validationErrors = validator.validate(this.state, ValidationConstraints, { format: "grouped" });

    if (validationErrors) {
      this.setState({
        firstNameError: validationErrors['firstName'],
        lastNameError: validationErrors['lastName'],
        npiError: validationErrors['npi'],
      });
    }
    else {
      const { firstName, lastName, npi, token, linkingToExistingProviderAccount } = this.state;

      const { registerService, submitRegister } = this.props;

      this.setState({ loading: true });

      try {
        const response = await npiService.isValidNameAndNpi(npi, firstName, lastName, token);

        if (response.ok) {
          registerService.updateRegisterState({ ...this.state, loading: false, page: 2 })

          if (linkingToExistingProviderAccount) {
            submitRegister(this.state);
          }
        }       
        else if (response.status === 401) {
          this.setState({ npiAndNameError: `Invite token has different provider information than what was provided`, loading: false, npiError: undefined });
        }
        else if (response.status === 400 || response.status === 404) {
          this.setState({ npiAndNameError: `NPI and Name are not valid`, loading: false, npiError: null });
        }
        else {
          this.setState({ npiAndNameError: NppesRegistrationError, loading: false, npiError: null });
        }
      } catch(error) {
        console.warn(error);

        this.setState({ npiAndNameError: NetworkConnectionError, loading: false, npiError: null });
      }
    }
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "firstName":
        this.setState({
          firstName: e.target.value,
        });
        break;
      case "lastName":
        this.setState({
          lastName: e.target.value,
        });
        break;
      case "npi":
        this.setState({
          npi: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  showTerms = () => {
    this.setState({
      showTermsAndConditions: true,
    });
  };

  hideTerms = () => {
    this.setState({
      showTermsAndConditions: false,
    });
  };

  setIsTermsAndConditionsChecked = () => {
    const { isTermsAndConditionsChecked } = this.state;

    this.setState({
      isTermsAndConditionsChecked: !isTermsAndConditionsChecked,
    });
  };

  setIsUserAgreementChecked = () => {
    const { isUserAgreementChecked } = this.state;

    this.setState({ isUserAgreementChecked: !isUserAgreementChecked });
  };

  componentDidMount() {
    const { registerService } = this.props
    this.subscription = registerService.subscribe(this.onStateChanged);
    const { registerState } = registerService.getState();

    if (registerState.providerInformation) {
      accountUserAgreementService
        .doesUserAgreementExistForProvider(
          registerState.providerInformation.invitedByAccountId,
        )
        .then((result) =>
          this.setState({
            ...registerState,
            providerInformation: {
              ...registerState.providerInformation,
              accountName: result?.accountName || undefined,
            },
          }),
        );
    }
  }

  componentWillUnmount() {
    const { registerService } = this.props
    registerService.unsubscribe(this.subscription);
  }

  shouldComponentUpdate = () => {
    return true;
  };

  onStateChanged = () => {
    const { registerService } = this.props;
    const { state } = registerService.getState();
    this.setState({ ...state });
  };

  render() {
    const {
      state: {
        npiAndNameError,
        firstNameError,
        lastNameError,
        npiError,
        loading,
        firstName,
        lastName,
        npi,
        isTermsAndConditionsChecked,
        showTermsAndConditions,
        isUserAgreementChecked,
        providerInformation: { invitedByAccountId, accountName },
      },
      props: { totalSteps },
    } = this;

    if (loading) {
      return <Loading />;
    }

    if (showTermsAndConditions) {
      return <TermsAndConditions onCancel={this.hideTerms} />;
    }

    return (
      <Content>
        <FuiFlexContainer
          className="contentsContainer"
          alignItems="center"
          direction="column"
          alley="medium"
        >
          <FuiFlexItem>
            <FuiHeading>Enter Your Name and NPI</FuiHeading>
          </FuiFlexItem>
          {this.renderMessage(npiAndNameError)}
          <FuiFlexItem className="registrationInput" padding="left">
            <FuiTextBox
              autoComplete="off"
              key={1}
              name="firstName"
              className="inputbox"
              placeholder="First Name"
              onChange={this.onChange}
              errors={firstNameError}
              defaultValue={firstName}
              onBlur={this.firstNameOnBlur}
            />
          </FuiFlexItem>
          <FuiFlexItem className="registrationInput" padding="left">
            <FuiTextBox
              autoComplete="off"
              key={2}
              name="lastName"
              className="inputbox"
              placeholder="Last Name"
              onChange={this.onChange}
              errors={lastNameError}
              defaultValue={lastName}
              onBlur={this.lastNameOnBlur}
            />
          </FuiFlexItem>
          <FuiFlexItem className="registrationInput" padding="left">
            <FuiTextBox
              className="inputbox"
              autoComplete="off"
              key={3}
              name="npi"
              placeholder="NPI Number"
              onChange={this.onChange}
              errors={npiError}
              defaultValue={npi}
              onBlur={this.npiOnBlur}
            />
          </FuiFlexItem>
          <FuiFlexContainer
            alignItems="flex-start"
            direction="column"
            alley="small"
          >
            <FuiFlexItem className="termsRow">
              <FuiCheckBox
                id="termsCheckBox"
                key={4}
                onChange={this.setIsTermsAndConditionsChecked}
                checked={isTermsAndConditionsChecked}
              />
              <FuiHeading size="xs" className="termsCheckBox">
                I have read and agree to the Forcura&nbsp;
                <button className="customLink" onClick={this.showTerms}>
                  <span>Terms and Conditions</span>
                </button>
              </FuiHeading>
            </FuiFlexItem>
            {accountName && (
              <UserAgreementAcceptanceList
                userAgreements={[
                  { accountName, accountId: invitedByAccountId },
                ]}
                onUpdateAcceptance={this.setIsUserAgreementChecked}
                userAgreementsAccepted={
                  isUserAgreementChecked ? [invitedByAccountId] : []
                }
                wrap={false}
              ></UserAgreementAcceptanceList>
            )}
          </FuiFlexContainer>
          <FuiFlexItem>
            <Steps totalSteps={totalSteps} currentStep={1} />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButton
              onMouseDown={this.onMouseDown}
              isDisabled={
                !isTermsAndConditionsChecked ||
                (accountName && !isUserAgreementChecked)
              }
            >
              Next
            </FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }

  renderMessage = (errorDescription) => {
    if (errorDescription && errorDescription !== null) {
      return (
        <FuiFlexItem padding="none">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <span className="error">{errorDescription}</span>
        </FuiFlexItem>
      );
    }

    return <> </>;
  };
}

NameAndNumber.propTypes = {
  submitRegister: PropTypes.func.isRequired,
  registerService: PropTypes.shape({}).isRequired,
  totalSteps: PropTypes.number.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
